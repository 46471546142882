// Demo Pages
.ButtonPage {
  .btn {
    margin: 4px;
  }

  .btn-group {
    .btn {
      margin: 0;
    }
  }
}

.TablePage {
  .card-body {
    overflow: hidden;
  }
}
