.rti--container, .rti--container:focus-visible, .rti--container:focus-within {
    align-items: center !important;
    background: var(--white) !important;
    border: 1px solid #ced4da !important;
    border-radius: 5px !important;
    display: flex !important;
    flex-wrap: wrap !important;
    gap: 0.37rem !important;
    line-height: 1.4 !important;
    padding: 0.45rem !important;
    margin-top: 9px !important;
    box-shadow: none !important;
}

.rti--tag {
    align-items: center !important;
    background: var(--primary) !important;
    color: var(--white);
    border-radius: 5px !important;
    display: inline-flex;
    justify-content: center;
    padding: 3px 5px !important;
    font-size: 12px !important;
}

.rti--input {
    border: 0;
    outline: 0;
    font-size: 12px !important;
    line-height: 1.6 !important;
    width: 50% !important;
}

.rti--tag button {
    background: none;
    border: 0;
    border-radius: 50%;
    cursor: pointer;
    line-height: inherit;
    padding: 0 0 0 10px !important;
    color: var(--white) !important;
    font-weight: 600;
}

.rti--tag button:hover {
    color: var(--white) !important;
}