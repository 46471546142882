.DemoWrapper {
    margin: 0 auto;
    max-width: 500px;
    padding: 0 24px;
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.Demo {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.DemoWrapper button {
    white-space: nowrap;
    border: 0;
    outline: 0;
    display: inline-block;
    height: 40px;
    line-height: 40px;
    padding: 0 14px;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    color: #fff;
    border-radius: 4px;
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.025em;
    background-color: #1ec2f3;
    text-decoration: none;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
    margin-top: 10px;
}

.DemoWrapper button:hover {
    color: #fff;
    cursor: pointer;
    background-color: #1ec2f38f;
    transform: translateY(-1px);
    box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
}

.DemoWrapper input,
.StripeElement {
    display: block;
    margin: 10px 0 20px 0;
    max-width: 500px;
    padding: 10px 14px;
    font-size: 16px;
    font-family: Poppins;
    /*box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,*/
    /*rgba(0, 0, 0, 0.0196078) 0px 1px 0px;*/
    border: 1px solid #dfdfdf;
    /*outline: 1px;*/
    border-radius: 4px;
    background: white;
}

.DemoWrapper input::placeholder {
    color: #aab7c4;
}

.StripeElement {
    width: 100%;
}

.DemoWrapper input:focus,
.StripeElement--focus {
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
}

#hero-bussiness-river {
    background-size: cover;
    background-repeat: no-repeat;
}

#hero-bussiness-river .bussiness-river-logo {
    max-width: 260px;
    margin-left: auto;
    object-fit: contain;
    margin-right: auto;
    display: block;
}

#hero-bussiness-river h1 {
    color: #1EC2F3;
    font-weight: 700;
    font-size: 32px;
    line-height: 1.6;
    margin-top: 10px;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
}

.StripeElement--invalid {
    border: 1px solid #f85032;
}

.ElementsApp .InputElement.is-invalid {
    color: #f85032;
}